import './schedule-schedule.scss'

import rules from "../../utils/rules";
import util from "@/utils/util";
import { mapState, mapGetters } from "vuex"
import _ from "lodash"

export default {
  props: {
    makeId: {
      type: String,
      default: ''
    },
    status: {
      type: Boolean,
      default: false // 1已完成 0预约中
    }
  },
  data: () => ({
    ruleForm: {},
    result: false, //防止表单重复提交
    error_title: '海葬预约详情接口出错',
    datePicker: [],
    cloneRuleForm: {},
    resultBtn:'保存',
    holdsPull: true, //默认可预约状态
    rules
  }),
  computed: {
    ...mapState({
      partnerId: state => state.user.partnerId,
      userInfo: state => state.user.userInfo,
      options: state => state.Sea.seaArea
    }),
    ...mapGetters(['getMaxPeople'])
  },
  watch: {
    result(val) {
      val ? this.resultBtn = '表单正在提交中' : '保存';
    },
    'ruleForm.cremainsCount'(currentValue, oldValue) {
      if (oldValue) {
        let addDead = () => {
          this.ruleForm.departedList.push({userName: '', userLocation: '', userIDcard: ''})
        };
        let deleteDead = () => {
          this.ruleForm.departedList.splice(currentValue, 1)
        };
        (currentValue - oldValue) > 0 ? addDead() : deleteDead()
      }
    },
    makeId() {
      this.getFuneralDetail()
    },
    deep: true
  },
  created() {
    this.result = false;
    this.getFuneralDetail()
  },
  mounted() {
    var inputs = document.getElementsByName('cremainsCount');
    for (let i=0; i < inputs.length; i++) {
      inputs[i].disabled = "disabled"
    }
  },
  methods: {
    changeDate(date) {
      if (date) {
        date.max_people - date.sum_people > 0 ? this.holdsPull = true : this.holdsPull = false
        this.$store.commit('updateMaxPeople', date.max_people - date.sum_people)
      }
    },
    getFuneralDetail() {
      const that = this;
      that.$api.website.getFuneralDetail({id: that.makeId})
        .then( dates => {
          that.cloneRuleForm = _.cloneDeep(util.jsonToHump(dates))
          const rusltDate = util.jsonToHump(dates)
          //暂时数据逝者数据处理
          Object.keys(rusltDate).forEach((key) => {
            if (key == 'seaFuneralPeriod' || key == 'user' || key == 'appointmentIdcard' || key == 'departedList') {
              delete(rusltDate[key]) // 删除对象的key。
            }
          })
          that.ruleForm = rusltDate
          let result = that.cloneRuleForm.departedList.map(o=>{return{userName:o.userName, userLocation:o.userLocation, userIDcard: o.userIdcard}});
          that.ruleForm['periodSite'] = that.cloneRuleForm.seaFuneralPeriod.periodSite
          that.$set(that.ruleForm, 'appointmentIDcard', that.cloneRuleForm.appointmentIdcard)
          that.$set(that.ruleForm, 'departedList', result)
          //that.ruleForm['departedList'] = [{userName: that.cloneRuleForm.userName, userLocation: that.cloneRuleForm.userLocation}]
          //this.$set(that.ruleForm, 'departedList', )
          that.directionChange()
        }).catch( err => {

        })
    },
    directionChange() {
      const that = this;
      that.$api.website.getFuneralWebList({periodSite: that.ruleForm.periodSite})
        .then( dates => {
          that.datePicker = []
          if (dates.data.length > 0) {
            dates.data.forEach( data => {
              that.datePicker.push(data)
            })
            if (this.ruleForm.appointmentPurpose != '代撒') {
              this.changeDate(dates.data[_.findIndex(dates.data, (o) => this.ruleForm.periodId == o.id)])
            }
          }
        }).catch( err => {
          that.$store.commit('showMessage',{
            message: err
        })
      })
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          //临时处理数据格式提交
          // this.ruleForm.departedList.forEach(data => {
          //   this.ruleForm['userName'] = data.userName
          //   this.ruleForm['userLocation'] = data.userLocation
          // })
          if (this.ruleForm.appointmentPurpose == '代撒') {
            this.$set(this.ruleForm, 'peopleCount', 0)
          }
          this.ruleForm['seaFuneralPeriod'] = {id: this.cloneRuleForm.seaFuneralPeriod.id}
          this.ruleForm['user'] = {id: this.userInfo.id}
          this.result = true
          this.$api.website.postFuneralWebUpdate(this.ruleForm)
            .then( dates => {
              this.result = false
              this.$bus.$emit('closeDialog')
            }).catch( err => {
              this.result = false
              this.$store.commit('showMessage', {
                message: err
              })
            })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    _renderFormCard(domain, index) {
      if (this.ruleForm.appointmentPurpose == '报名') {
        return (
          <el-form-item
            label={`故 者${index+1}身份证号：`}
            prop={'departedList.' + index + '.userIDcard'}
          >
            <el-input v-model={domain.userIDcard} placeholder={`请填写海葬故者${index+1}的身份证号`} disabled={this.status}></el-input>
          </el-form-item>
        )
      }
    },
    _renderDeadView() {
      let censusRegisterWay = (domain, index) => {
        if (this.ruleForm.address == '沈阳市') {
          return (
            <el-form-item
              label={`故 者${index+1}户 籍：`}
              prop={'departedList.' + index + '.userLocation'}
              rules={this.rules.censusRegister}
            >
              <el-select
                v-model={domain.userLocation}
                multiple={false}
                filterable={true}
                allow-create={true}
                default-first-option={true}
                disabled={this.status}
                placeholder={`请选择或输入故者${index+1}户籍`}>
                {
                  this.options.map(data =>
                    <el-option key={data} label={data} value={data}></el-option>
                  )
                }
              </el-select>
            </el-form-item>
          )
        } else {
          return (
            <el-form-item
              label={`故 者${index+1}户 籍：`}
              prop={'departedList.' + index + '.userLocation'}
              rules={this.rules.inCensusRegister}
            >
              <el-input v-model={domain.userLocation} disabled={this.status} placeholder={`请输入外地故者${index+1}户籍`}></el-input>
            </el-form-item>
          )
        }
      }
      if (this.ruleForm.departedList) {
        return (
          this.ruleForm.departedList.map((domain, index) =>
            <div class="dialog-item">
              <el-form-item
                label={`故 者${index+1}姓 名：`}
                prop={'departedList.' + index + '.userName'}
                rules={this.rules.deadName}
              >
                <el-input v-model={domain.userName} disabled={this.status} placeholder={`请填写海葬故者${index+1}的名字`}></el-input>
              </el-form-item>
              {/*{*/}
              {/*  this._renderFormCard(domain, index)*/}
              {/*}*/}
              <el-form-item
                label={`故 者${index+1}身份证号：`}
                prop={'departedList.' + index + '.userIDcard'}
              >
                <el-input v-model={domain.userIDcard} placeholder={`请填写海葬故者${index+1}的身份证号`} disabled={this.status}></el-input>
              </el-form-item>
              {censusRegisterWay(domain, index)}
            </div>
          )
        )
      }
    },
    _renderDatePicker(dates) {
      let _renderPeriodIdRadio = (ele) => {
        if (this.ruleForm.appointmentPurpose == '代撒') {
          return (
            <el-radio key={ele.id} label={ele.id} border>{ele.period_year}/{ele.period_time}</el-radio>
          )
        } else {
          return (
            <el-radio onChange={() => this.changeDate(ele)} key={ele.id} label={ele.id} border>{ele.period_year}/{ele.period_time}(余{ele.max_people - ele.sum_people}个)</el-radio>
          )
        }
      }
      if (dates.length > 0) {
        return (
          <div class="dialog-date">
            <el-form-item label="日 期 选 择：" prop="periodId" rules={this.rules.Change}>
              <el-radio-group v-model={this.ruleForm.periodId} size="mini" disabled={this.status}>
                { dates.map((ele) =>
                  _renderPeriodIdRadio(ele)
                )}
              </el-radio-group>
            </el-form-item>
          </div>
        )
      }
    },
    _renderCremainsCount() {
      if(this.ruleForm.cremainsCount) {
        let cremainsCountLabel
        this.ruleForm.appointmentPurpose == '祭祀' ? cremainsCountLabel = '祭祀故者数量：' : cremainsCountLabel = '故 者 数 量：'
        return (
          <el-form-item label={cremainsCountLabel} prop="cremainsCount" rules={this.rules.InterNum}>
            <el-input-number  v-model={this.ruleForm.cremainsCount} name="cremainsCount" type="number" min={1} label="描述文字" disabled={this.status}></el-input-number>
          </el-form-item>
        )
      }
    },
    _renderFormAppointCard() {
        return (
          <el-form-item label="身份证号：" prop="appointmentIDcard" rules={rules.checkIdNum}>
            <el-input v-model={this.ruleForm.appointmentIDcard} placeholder="请填写报名人身份证号码" disabled={this.status}></el-input>
          </el-form-item>
        )
    },
    _renderPeopleCount() {
      let changePeople = (currentValue, oldValue) => {
        const h = this.$createElement
        this.$set(this.ruleForm, 'peopleCount', currentValue)
        if (currentValue >= this.getMaxPeople) {
          this.$notify({
            title: '提示',
            message: h('i', { style: 'color: teal'}, `当前方向预约人数已超过最大可预约人数${this.getMaxPeople}人`)
          });
        }
      }
      let renderCountPrompt = () => {
        if (this.ruleForm.peopleCount > 3 ){
          return (
            <div class="sea-userInfo-remark">备注：请注意禁止虚报人数</div>
          )
        } else {
          return (
            <div class="sea-userInfo-remark">备注：禁止13周岁以下70周岁以上人员参加</div>
          )
        }
      }
      if (this.ruleForm.appointmentPurpose != '代撒') {
        return (
          <el-form-item label="参 加 人 数：" prop="peopleCount" rules={this.rules.InterNum}>
            <el-input-number value={this.ruleForm.peopleCount}  onChange={(currentValue, oldValue) => changePeople(currentValue, oldValue)} min={1} max={this.getMaxPeople} label="描述文字" disabled={this.status}></el-input-number>
            {renderCountPrompt()}
          </el-form-item>
        )
      }
    },
    _renderAddress() {
      if (this.ruleForm.appointmentPurpose != '祭祀') {
        return(
          <el-form-item label="故 者 户 籍：" prop="address" rules={this.rules.Change}>
            <el-radio-group v-model={this.ruleForm.address} class="multiple-choice" disabled={this.status}>
              <el-radio label="沈阳市">沈阳市</el-radio>
              <el-radio label="外地">外地</el-radio>
            </el-radio-group>
          </el-form-item>
        )
      }
    },
    changePurpose(val) {
      this.changeDate(this.datePicker[_.findIndex(this.datePicker, (o) => this.ruleForm.periodId == o.id)])
      switch (val) {
        case '祭祀' :
          delete this.ruleForm.departedList;
          break;
        case '代撒':
          this.$set(this, 'holdsPull', true)
          break;
        default :
          this.$set(this.ruleForm, 'departedList', [{userName: '', userLocation: '', userIDcard: ''}])
      }
      console.log(this.ruleForm)
    },
    _renderSubmitBtn() {
      if (this.holdsPull && !this.status) {
        return (
          <button type="button" disabled={this.status || this.result} onClick={() => this.submitForm('ruleForm') } class={`dialog-btn ${this.result ? 'not-cot' : ''}`}>{this.resultBtn}</button>
        )
      } else if(!this.holdsPull) {
        return (
          <button type="button" style={{'background': '#ededed' , 'color': '#fff'}}>暂无可预约的数据</button>
        )
      }
    }
  },
  render() {
    return (
      <div class="dialog-schedule-form">
        <el-form inline={true} props={{ model: this.ruleForm}}  ref="ruleForm" class="demo-form-inline">
          <div class="dialog-item">
            <el-form-item label="海葬方向:" prop="periodSite" rules={this.rules.Change}>
              <el-select v-model={this.ruleForm.periodSite} onChange={() => this.directionChange()} disabled={this.status}>
                <el-option label="大连方向" value="大连"></el-option>
                <el-option label="营口方向" value="营口"></el-option>
                <el-option label="丹东方向" value="丹东"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="参加项目" prop="appointmentPurpose" rules={this.rules.Change}>
              <el-select  v-model={ this.ruleForm.appointmentPurpose } onChange={() => this.changePurpose(this.ruleForm.appointmentPurpose)} disabled={this.status}>
                <el-option label="报名" value="报名"></el-option>
                <el-option label="祭祀" value="祭祀"></el-option>
                <el-option label="代撒" value="代撒"></el-option>
              </el-select>
            </el-form-item>
            {this._renderAddress()}
          </div>
          {this._renderDatePicker(this.datePicker)}
          <div class="dialog-item">
            <el-form-item label="报名人姓名：" prop="appointmentName" rules={this.rules.Name}>
              <el-input v-model={this.ruleForm.appointmentName} disabled={this.status}></el-input>
            </el-form-item>
            {this._renderFormAppointCard()}
            {this._renderCremainsCount()}
          </div>
          {this._renderDeadView()}
          <div class="dialog-item">
            {this._renderPeopleCount()}
            <el-form-item label="联系电话：" prop="appointmentTel" rules={this.rules.phone}>
              <el-input v-model={this.ruleForm.appointmentTel} disabled={this.status}></el-input>
            </el-form-item>
          </div>
          <div class="dialog-item">
            <el-form-item label="备用电话：" prop="appointmentAlterTel">
              <el-input v-model={this.ruleForm.appointmentAlterTel} disabled={this.status}></el-input>
            </el-form-item>
            <el-form-item label="备    注：" prop="notes">
              <el-input v-model={this.ruleForm.notes} disabled={this.status}></el-input>
            </el-form-item>
          </div>
          <div class="dialog-btn">
            {this._renderSubmitBtn()}
          </div>
        </el-form>
      </div>
    )
  }
}
