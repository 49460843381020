<template>
  <div class="personal-schedule">
    <div class="personal-title">
      <div class="title-bg">
        <h4>办件进程</h4>
      </div>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>当前位置：</el-breadcrumb-item>
        <el-breadcrumb-item to="/">首页</el-breadcrumb-item>
        <el-breadcrumb-item>办件进程</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="schedule-container">
      <div class="schedule-content">
        <div class="schedule-content-card"  v-if="AppointmentList.length > 0" v-for="(app, index) in AppointmentList">
          <div class="content-card-top">
            <div class="card-top-matter">{{app.appointment_purpose}}</div>
            <div class="card-top-status">{{app.status_desc}}</div>
          </div>
          <div class="content-card-bot">
            <div class="card-body">
              <div class="body-time flex-div">
                <div class="box-item">
                  <i class="sea-iconfont">&#xe61f;</i>
                  <div class="common-content"><span class="label-content">预约时间：</span>{{app.create_time}}</div>
                </div>
                <div class="box-item">
                  <i class="sea-iconfont">&#xe692;</i>
                  <div class="common-content"><span class="label-content">活动出发时间：</span>{{app.sea_funeral_period.period_time}}</div>
                </div>
              </div>
              <div class="body-address flex-div">
                <div class="box-item">
                  <i class="sea-iconfont">&#xe640;</i>
                  <div class="common-content"><span class="label-content">活动方向：</span>{{app.sea_funeral_period.period_site}}</div>
                </div>
                <div class="box-item" v-if="app.people_count != 0">
                  <i class="sea-iconfont">&#xe630;</i>
                  <div class="common-content"><span class="label-content">参加人数：</span>{{app.people_count}}</div>
                </div>
              </div>
              <div class="body-name flex-div">
                <div class="box-item">
                  <i class="sea-iconfont">&#xe611;</i>
                  <div class="common-content"><span class="label-content">报名人：</span>{{app.appointment_name}}
<!--                    <span class="card-phone">{{app.appointment_tel}}</span>-->
                  </div>
                </div>
                <div class="box-item" v-if="app.departed_list">
                  <i class="sea-iconfont">&#xe685;</i>
                  <div class="name-list">
                    <div
                      class="name-content-item"
                      v-for="(record, j) in app.departed_list"
                      :key="j"
                    >
                      <span style="margin-right: 20px" v-if="record.user_name || record.user_location ">故者{{ j + 1 }}：</span>
                      <span class="row" v-if="record.user_name || record.user_location "><span class="name">{{ record.user_name }}</span>{{ record.user_location }}</span>
                      <span v-else>故者信息资料为空</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="operate-btn">
              <button type="button" v-if="app.status === 0" @click="openDialog(app)" :style="statusColor(0)">预约修改</button>
              <button type="button" v-if="app.status === 0" @click="cancelMake(app.id)" :style="statusColor(1)">取消预约</button>
              <button type="button" v-if="app.status === 0" @click="detMake(app.id)" :style="statusColor(2)">删除</button>
              <button type="button" v-if="app.status === 1" @click="openDetailDialog(app)" :style="statusColor(0)">查看已预约</button>
            </div>
          </div>
        </div>
        <xgj-empty v-if="AppointmentList.length === 0" title="暂无办件进度数据"></xgj-empty>
      </div>
      <!--分页-->
      <div class="news-pages"  v-if="AppointmentList.length > 0">
        <el-pagination
          background
          @size-change="sizeDetailChange"
          @current-change="currentDetailChange"
          :current-page.sync="detail_paging.page"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="detail_paging.size"
          :total="detail_paging.total_elements"
          layout="total, sizes, prev, pager, next, jumper">
        </el-pagination>
      </div>
    </div>
    <el-dialog :visible.sync="dialogFormVisible"
               width="1080px"
               :modal-append-to-body="false"
               :show-close="false"
               :close-on-click-modal="false"
               :close-on-press-escape="false"
               custom-class="dialog-schedule">
      <div slot="title" class="dialog-title">
        <div class="dialog-title-lef">{{ this.title }}</div>
        <div class="dialog-title-rig">
<!--          <i class="sea-iconfont icon-iconxiazai"></i>-->
          <i @click="closeDialog" class="sea-iconfont icon-cha"></i>
        </div>
      </div>
      <schedule-detail v-if="dialogFormVisible" :makeId="makeId" :status="status"></schedule-detail>
    </el-dialog>
  </div>
</template>

<script>
  import scheduleDetail from '@/components/personal-schedule/schedule-detail.js'
  import { mapState } from 'vuex'

  export default {
    name: 'personal-schedule',
    data() {
      return {
        dialogFormVisible: false,
        AppointmentList: [],
        makeId: null,
        status: false,
        detail_paging: {  //分页
          page: 1,
          size: 10,
          total_elements: 0
        },
        title:''
      }
    },
    computed: {
      ...mapState({
        partnerId: state => state.user.partnerId,
        userInfo: state => state.user.userInfo
      })
    },
    created() {
      this.getSeaFuneralAppointmentList()
    },
    mounted() {
      this.$bus.$on('closeDialog', () => {
        this.closeDialog()
      })
    },
    //注意：在组件销毁时，一定要解除绑定事件：
    destroyed(){
      this.$bus.$off('closeDialog');
    },
    components: {
      scheduleDetail
    },
    methods: {
      //改变每页数量
      sizeDetailChange(size) {
        this.detail_paging.size = size;
        this.getSeaFuneralAppointmentList()
      },
      detMake(id) {
        this.$api.website.detSeaFuneralCancel({id: id})
          .then( dates => {
            this.$store.commit('showMessage', {
              message: '删除成功',
              type: 'success'
            })
            this.getSeaFuneralAppointmentList()
          }).catch( err => {
            this.$store.commit('showMessage', {
              message: err
            })
          })
      },
      cancelMake(id) {
        this.$api.website.SeaupdateStatus({id: id})
          .then( dates => {
            this.$store.commit('showMessage', {
              message: '取消成功',
              type: 'success'
            })
            this.getSeaFuneralAppointmentList()
          }).catch( err => {
          this.$store.commit('showMessage', {
            message: err
          })
        })
      },
      //改变页数
      currentDetailChange(page) {
        this.detail_paging.page = page;
        this.getSeaFuneralAppointmentList()
      },
      getSeaFuneralAppointmentList() {
        const that = this;
        const loading = this.$loading({
          lock: true,
          text: '加载中',
          background: 'rgba(0, 0, 0, 0.3)'
        })
        let params = {
          partnerId: that.partnerId,
          userId: that.userInfo.id,
          page: that.detail_paging.page,
          rows: that.detail_paging.size
        }
        that.AppointmentList = []
        that.$api.website.getSeaFuneralAppointmentList(params)
          .then( dates => {
            console.log(dates)
            that.AppointmentList = dates.data.map(( data ) => {
              return data
            })
            that.detail_paging.size = dates.paging.page_size
            that.detail_paging.page = dates.paging.current_page
            that.detail_paging.total_elements = dates.paging.records
            loading.close()
          }).catch( err => {
            loading.close()
        })
      },
      openDialog(app) {
        // this.dialogFormVisible = true
        this.makeId = app.id
        app.status === 1 ? this.status = true : this.status = false
        if( app.impose > 0) {
          this.$confirm('网上修改预约信息只能修改一次，需要再次修改请致电024-23475222由工作人员进行修改', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
            lockScroll: true
          }).then(() => {
            this.status = true
            setTimeout(() => {
              this.dialogFormVisible = true;
              this.title = "预约详情";
            },300)
          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消修改'
            });
          });
        } else {
          this.title = "修改预约";
          this.dialogFormVisible = true
        }
      },

      openDetailDialog(app) {
        // this.dialogFormVisible = true
        this.makeId = app.id
        app.status === 1 ? this.status = true : this.status = false;
        this.title = "预约详情";
        this.dialogFormVisible = true
      },

      closeDialog() {
        this.getSeaFuneralAppointmentList()
        this.dialogFormVisible = false
      },
      statusColor(val) {
        switch (val) {
          case 0 :
            return 'background: linear-gradient(267deg, #F59A64 0%, #EE763B 100%);'
            break;
          default:
            return 'background: linear-gradient(234deg, #7AAADA 0%, #407EB2 100%);'
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
.dialog-schedule {
  background: #ffffff;
  width: 100%;
  .dialog-title {
    background: linear-gradient(-90deg, #79AAD9 0%, #407DB2 100%);
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    .dialog-title-lef {
      font-size: 19px;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 19px;
    }
    .dialog-title-rig {
      color: #ffffff;
      i {
        font-size: 24px;
        margin-right: 12px;
        cursor: pointer;
      }
    }
  }
}
/deep/ .el-dialog__header{padding: 0}
@import "./personal-view.scss";
</style>
